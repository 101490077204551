let currentIndex = 0;
let previousSlideIndex = 0;

let slides = document.getElementsByClassName('slides');

let testimonialsPrev = document.getElementById('testimonialsPrev');
let testimonialsNext = document.getElementById('testimonialsNext');

let testimonialsNav0 = document.getElementById('testimonialsNav0');
let testimonialsNav1 = document.getElementById('testimonialsNav1');
let testimonialsNav2 = document.getElementById('testimonialsNav2');
let testimonialsNavBtns = document.getElementsByClassName('testimonials-radio-button');

// load event listeners when the DOM is loaded
document.addEventListener('DOMContentLoaded', startup());

// begin startup()
function startup() {
	initEventListeners();
	liveSlide(currentIndex = 0);
}// end startup()

// begin initEventListeners()
function initEventListeners() {
	testimonialsPrev.addEventListener('click', function() { nextSlide(-1); });
	testimonialsNext.addEventListener('click', function() { nextSlide(1); });
	testimonialsNav0.addEventListener('click', function() { liveSlide(0); });
	testimonialsNav1.addEventListener('click', function() { liveSlide(1); });
	testimonialsNav2.addEventListener('click', function() { liveSlide(2); });
}// end initEventListeners()

// determine next slide (left or right)
function nextSlide(x) {
	previousSlideIndex = currentIndex;
	currentIndex += x;

	if(currentIndex < 0)
		currentIndex = slides.length - 1;
	else if(currentIndex > slides.length -1)
		currentIndex = 0;
	//currentIndex %= slides.length;
	determineSlide();
}// end nextSlide(...)

// determine live slide
function liveSlide(x) {
	previousSlideIndex = currentIndex;
	currentIndex = x;
	determineSlide();
}// end liveSlide()

// determine which slide to show and which nav button to be selected
function determineSlide() {
	// hide previously displayed slide and deselect nav button
	slides[previousSlideIndex].style.display = 'none';
	testimonialsNavBtns[previousSlideIndex].className = 'testimonials-radio-button';
	//testimonialsNavBtns[previousSlideIndex].className.replace(' selected', '')

	// display selected slide and select nav button
	slides[currentIndex].style.display = 'flex';
	testimonialsNavBtns[currentIndex].className += ' selected';
}// end determineSlide()
